@import '../../style/variables.scss';

.App-header {
    .container {
        background: $white;
    
        .nav-menu_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $main-font;
            color: $black;
            margin: 0 auto;
            transition: $trasition;
            padding: 30px 0;
    
            &.menuShrunk {
                padding: 15px 0;
            }
        
            @media (max-width: $mobile-large) {
               align-items: center;
               padding: 20px 0;
    
                &.menuShrunk {
                    padding: 10px 0;
                }
            }
        
            .nav-logo {
                width: auto;
                cursor: pointer;
        
                @media (max-width: $tablet) {
                    max-width: 200px;
                }
            }
        
            .nav-menu {
                display: flex;
        
                .nav-list {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    list-style: none;
            
                    .nav-li {
                        padding: 0 20px;
                        font-size: 16px;
                        transition: $trasition;
                        cursor: pointer;
            
                        &:hover {
                            text-decoration: underline;
                            color: #767676;
                        }
                    }
        
                    @media (max-width: $tablet-small) {
                        display: none;
                    }
                }
    
                .contact-button_container {
                    padding: 15px 10px;
                    border: 1px solid $text-strong;
                    border-radius: 8px;
                    transition: $trasition;
                    cursor: pointer;
    
                    .contact-button {
                        transition: $trasition;
                    }
    
                    &:hover {
                        border: 1px solid $main-green;
                        background: $main-green;
    
                        .contact-button {
                            color: $white;
                        }
                    }
    
                    @media (max-width: $tablet-small) {
                        display: none;
                    }
                }
        
                .burger-container {
                    display: none;
        
                    .burger-btn {
                        z-index: 5;
        
                        @media (max-width: $mobile-large) {
                            margin: 0 50%;
                        }
                    }
        
                    .nav-list_burger {
                        position: absolute;
                        overflow: hidden;
                        transition: $trasition;
                        color: $black;
                        background: $white;
                        right: -100%;
                        left: 100%;
                        bottom: 0;
                        top: 0;
                        z-index: 10;
    
                        .menu-header {
                            border-bottom: 1px solid $main-green;
                            padding: 16px 0;
                            display: flex;
                            justify-content: space-between;
    
                            * {
                                margin: 0 16px;
                            }
                        }
    
                        .nav-list_menu {
                            padding: 0 16px 16px 16px;
                        }
        
                        .nav-li {
                            text-align: left;
                            border-top: 1px solid $main-green;
                            padding: 15px 35px;
                            text-align: center;
    
                            &:first-child {
                                border-top: none;
                            }
                        }
                    }
        
                    .nav-show {
                        left: 0;
                        right: 0;
                        height: 100vh;
                        max-width: 100vw;
                    }
        
                    @media (max-width: $tablet-small) {
                        display: flex;
                        justify-content: end;
                        width: 300px;
                    }
        
                    @media (max-width: $mobile-large) {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }
    
                @media (max-width: $mobile) {
                    margin-top: 10px;
                }
            }
        }
    }
}