@import './reset.scss';
@import './variables.scss';

.App {
    overflow: hidden;
    scroll-behavior: smooth;
    box-sizing: border-box;
    position: relative;

    .container {
        max-width: 90%;
        margin: 0 auto;
    }
}

